const ACCOUNT_ID = "accountid" as string;

export const getAccountID = (): string | null => {
  return window.sessionStorage.getItem(ACCOUNT_ID);
};

export const saveAccountID = (acctID: string): void => {
  window.sessionStorage.setItem(ACCOUNT_ID, acctID);
};

export const destroyAccountID = (): void => {
  window.sessionStorage.removeItem(ACCOUNT_ID);
};

export default { getAccountID, saveAccountID, destroyAccountID };
