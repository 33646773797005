import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import JwtToken from "@/core/services/JwtService";
import { Role } from "@/core/helpers/Role";
import handlesProfile from "@/core/services/HandleProfileIDService";
import MsalAuthService from "@/core/services/MsalAuthService";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { bool } from "yup";
import { TemporaryCacheKeys } from "@azure/msal-browser/dist/utils/BrowserConstants";
import { date } from "yup/lib/locale";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/invoices/pending-invoices",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      auth: true,
      authorizeRole: [],
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          auth: true,
          authorizeRole: [],
        },
      },
      {
        path: "reporting",
        name: "reporting",
        component: () => import("@/views/Reports/Reporting.vue"),
        meta: {
          auth: true,
          authorizeRole: [
            Role.SuperAdmin,
            Role.Admin,
            Role.PayableUser
          ],
        },
      },
      {
        path: "user-management",
        name: "user-management",
        component: () => import("@/views/UserManagement/UserManagement.vue"),
        meta: {
          auth: true,
          authorizeRole: [Role.Admin],
        },
      },
      {
        path: "client-management",
        name: "client-management",
        component: () => import("@/views/UserManagement/ClientManagement.vue"),
        meta: {
          auth: true,
          authorizeRole: [Role.SuperAdmin],
        },
      },
      
      {
        path: "/JobSite/",
        name: "jobsite",
        component: () => import("@/components/page-layouts/JobSites.vue"),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "/JobSite/Map",
            name: "map",
            component: () => import("@/views/JobSite/Assets.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser
              ],
            },
          },
          {
            path: "/JobSite/Accounts",
            name: "accounts",
            component: () => import("@/views/JobSite/Accounts.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
              ],
            },
          }
        ]
      },
      {
        path: "/invoices/",
        name: "invoices",
        component: () => import("@/components/page-layouts/Invoices.vue"),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "pending-invoices",
            name: "pending-invoices",
            component: () => import("@/views/Invoices/UnpaidInvoices.vue"),
            meta: {
              auth: true,
              authorizeRole: [Role.SuperAdmin, Role.Admin, Role.PayableUser],
            },
          },
          {
            path: "open-invoices-view",
            name: "open-invoices-view",
            component: () => import("@/views/Invoices/OpenInvoicesView.vue"),
            meta: {
              auth: true,
              authorizeRole: [Role.ReadOnly],
            },
          },
          {
            path: "paid-invoices",
            name: "paid-invoices",
            component: () => import("@/views/Invoices/PaidInvoices.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          },
          {
            path: "all-invoices",
            name: "all-invoices",
            component: () => import("@/views/Invoices/AllInvoices.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          },
          {
            path: "inprogress-invoices",
            name: "inprogress-invoices",
            component: () => import("@/views/Invoices/InProgressInvoices.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          },
          {
            path: "payment-history",
            name: "payment-history",
            component: () => import("@/views/Payments/PaymentHistory.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          },
          {
            path: "add-payment-method",
            name: "add-payment-method",
            component: () => import("@/views/Payments/AddPaymentMethod.vue"),
            meta: {
              auth: true,
              authorizeRole: [Role.SuperAdmin, Role.Admin, Role.PayableUser],
            },
          },
          {
            path: "view-payment-method",
            name: "view-payment-method",
            component: () => import("@/views/Payments/ViewPaymentMethod.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          }
        ],
      },
      {
        path: "/Profile/",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "edit-profile",
            name: "edit-profile",
            component: () => import("@/views/Profile/EditProfile.vue"),
            meta: {
              auth: true,
              authorizeRole: [
                Role.SuperAdmin,
                Role.Admin,
                Role.PayableUser,
                Role.ReadOnly,
              ],
            },
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () => import("@/views/Profile/Activity.vue"),
            meta: {
              auth: true,
              authorizeRole: [],
            },
          },
        ],
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
        meta: {
          auth: true,
          authorizeRole: [],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/auth-flow/SignIn.vue"),
        meta: {
          auth: false,
          authorizeRole: [],
        },
      },
    ],
  },
  {
    path: "/AccessDenied",
    name: "AccessDenied",
    component: () => import("@/views/authentication/AccessDenied.vue"),
    meta: {
      auth: false,
      authorizeRole: [],
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/authentication/Error404.vue"),
    meta: {
      auth: false,
      authorizeRole: [],
    },
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/authentication/Error500.vue"),
    meta: {
      auth: false,
      authorizeRole: [],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: {
      auth: false,
      authorizeRole: [],
    },
  },
];
let isAccessable = true;
let hasCustomerId = true;
let isAccessDeniedPage = false;
let base_url = '';
const FindRoute = (toUrl, ParentPath, ChRoute): boolean => {
  let currentUserRole = "0";
  const currentProfile = handlesProfile.getProfile();
  if (currentProfile) {
    //!== undefined || handlesProfil0e.getProfile() !== null
    currentUserRole = currentProfile.extension_Role;
    if (
      currentUserRole === "0" ||
      currentUserRole === null ||
      currentUserRole === undefined
    ) {
      currentUserRole = "11";
    }
  }

  for (let i = 0; i < ChRoute.length; i++) {
    if (
      (ParentPath + ChRoute[i].path).toLowerCase() === toUrl.path.toLowerCase()
    ) {
      if (ChRoute[i].meta) {
        const tmp = ChRoute[i].meta || { auth: false, authorizeRole: [] };
        if (tmp != null && tmp.auth) {
          if (!tmp.authorizeRole.includes(currentUserRole)) {
            isAccessable = false;
          }
          const currentProfile = handlesProfile.getProfile();
          if(currentProfile)
          {
            const customerID = currentProfile.extension_WACustomerId | (null as any);
            if(customerID === 0 || customerID === undefined || customerID === null)
            {
              hasCustomerId = false;
            }
          }

          return true;
        }
      }
    } else if (ChRoute[i].children != undefined) {
      if (FindRoute(toUrl, ChRoute[i].path, ChRoute[i].children)) {
        return true;
      }
    }
  }
  return false;
};
const router = createRouter({
  history: createWebHistory(),
  routes,
});
interface metadata {
  auth: boolean;
  authorizeRole: [];
}
router.beforeResolve((to, from, next) => {
  let routePath = false;
  const authToken = JwtToken.getToken();
  isAccessable = true;
  routePath = FindRoute(to, "", routes);
  if (routePath && (authToken == null || authToken == "")) next("/sign-in");
  else {
    if(!hasCustomerId){
      //MsalAuthService.logout();
      isAccessable = false;
      isAccessDeniedPage = true;
    }
      if (isAccessable) {
        if (to.meta.auth) {
          if (handlesProfile.getProfile() !== undefined) {
            // BUG:  Failed to check for null profile
            const prof = handlesProfile.getProfile();
            if (prof) {
              const expString = prof.exp | (null as any);
              if (expString !== undefined) {
                // if(expString === null)
                // {
                //   MsalAuthService.logout();
                // }
                const expDate = expString as number;
                const dateNowString = parseInt(
                  (new Date().getTime() / 1000) as any
                );
                if (expDate < dateNowString) {
                  next("/sign-in");
                }
              }
            } else {
              MsalAuthService.logout();
            }
          }
        }
        if(to.name === 'AccessDenied' && handlesProfile.getProfile() === undefined)
        next("/sign-in");
        else
        next();
      } else {
        if(to.name === 'AccessDenied')
        next();
        else{
          if(isAccessDeniedPage)
          {
              //next("/accessdenied");
              base_url = window.location.origin;
              window.location.href = base_url+'/'+'AccessDenied';
          }
          else
          next("/404");
        }
      }

    
  }

  // if (authorize.authorizeRole.length && !authorize.includes(currentUserRole)) {
  //   // role not authorised so redirect to home page
  //   next('/sign-in');

  // reset config to initial state
  // store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
export default router;