const authBase = "discountwasteapp"; //PROD
const clientId = "487e0f19-2f32-4b3b-ac16-f1772baa988b"; //PROD
const signupUserFlow = "B2C_1_DW1-SignInSignUp"; //PROD
const resetUserFlow = "B2C_1_DW1-ResetPassword"; //PROD
//const authBase = 'externalwasteapps'; //QA
//const clientId = 'daef0902-fd32-4839-a0fa-887ccfb5c4d8'; //QA
const b2cPolicies = {
  names: {
    signUpSignIn: signupUserFlow,
    forgotPassword: resetUserFlow,
    editProfile: "",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${authBase}.b2clogin.com/${authBase}.onmicrosoft.com/${signupUserFlow}`,
    },
    forgotPassword: {
      authority: `https://${authBase}.b2clogin.com/${authBase}.onmicrosoft.com/${resetUserFlow}`,
    },
  },
  authorityDomain: `${authBase}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: clientId, //"7aec2f377-de6d-4c58-a6b8-badc43c52574", //"6105fa54-45df-4dab-a33d-ebed7d15bccf", //"2fdd06f3-7b34-49a3-a78b-0cf1dd87878e", // Replace with your AppID/ClientID obtained from Azure Portal.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.

    redirectUri: "https://customer.dw1.com/sign-in", //(Production)
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "sessionStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};

export default { msalConfig, loginRequest };
