import { createApp } from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faInfo, faInfoCircle,faTruck,faCalendarCheck,faMoneyBillWave,faEnvelopeOpenText,faFileInvoiceDollar,faFileInvoice,faLocationDot,faLandmark,faRefresh,faXmark,faTimeline,faBuildingUser} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faInfoCircle,faTruck,faCalendarCheck,faMoneyBillWave,faEnvelopeOpenText,faFileInvoiceDollar,faFileInvoice,faLocationDot,faLandmark,faRefresh,faXmark,faTimeline,faBuildingUser)

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);

app.use(VueGtag, { config: { id: "G-LL4BBH82Z6", }, }, router);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
//MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
